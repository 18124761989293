@import "../../assets/styles/utils";
.banner {
    overflow: hidden;
    width: 100%;
    height: 300px;
    position: relative;
    .model {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.3);
        z-index: 10;
    }
    .pic {
        width: 100%;
        height: 100%;
        transition: all .5s;
        div {
            position: absolute;
            transition: all .5s;
            opacity: 0;
            width: 100%;
            height: 100%;
        }
    }
    .title {
        top: 80px;
        @include res(top, 80px, (md:50px,xs:34px));
        position: absolute;
        width: fit-content;
        height: calc(100% + 50px);
        @include res(left , 512px , (mmd:330px,md:220px,xs:19%));
        border-left: 1px solid rgba($color: #ffffff, $alpha: 0.3);
        z-index: 10;
        .line {
            @include res(display, block, (xs:none));
            position: absolute;
            left: -2px;
            top: 40%;
            transform: translateY(-50%);
            div {
                position: relative;
                width: 4px;
                @include res(height, 85px,(lg:60px,mmd:51px,sm:42px));
                background: #01847F;
                @include res(top, 105px, (lg:95px,mmd:83px,md:70px,sm:64px,xs:23px));
                transition: all .3s;
            }
        }
        .banner-nav {
            position: relative;
            @include res(top, 40%, (xs:32%));
            transform: translateY(-50%);
            div {
                width: 100%;
                height: fit-content;
                @include res(margin-bottom, 0,(xs:20px));
                    &:last-child {
                        @include res(margin-bottom, 0, (xs:0));
                    }
                h2 {
                    transform: rotate(-15deg);
                    @include res(padding-left, 0, (xs:0));
                    color: rgba($color: #ffffff, $alpha: .3);
                    font-family: 'ProximaNova-Black';
                    @include res(font-size, 80px, 32 / 80);
                    font-weight: bold;
                    transition: all .3s;
                    text-transform:uppercase;
                    font-style: oblique;
                }
                &.active {
                    h2 {
                        color: #ffffff;
                    }
                }
            }
        }
        transform: rotate(15deg);
    }
    .scroll {
        @include res(display, block, (md:none));
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 11;
        span {
            @include res(font-size, 20px, 16 / 20);
            font-family: 'ProximaNova-Black';
            display: block;
            color: #fff;
            margin-bottom: 58px;
            margin-right: 155px;
        }
        .line {
            width: 1px;
            @include res(height, 1000px, 300 / 1000);
            background: rgba($color: #ffffff, $alpha: .3);
            transform: rotate(15deg);
            position: absolute;
            bottom: -30px;
            right: 0;
        }
    }
}
// 关于我们
.about {
    position: relative;
    h2 {
        position: relative;
        z-index: 1;
        text-align: right;
        font-family: 'ProximaNova-Black';
        @include res(font-size, 90px, 30 / 90);
        color: #01847f;
    }
    .content {
        @include res(margin-top, 60px, 30 / 60);
        @include res(display, flex, (md:block));
        justify-content: space-between;
        img {
            display: block;
        }
        .left {
            z-index: 1;
            @include res(position, relative, (md:static));
            @include res(width, 694px,(lg:57.8%,md:100%));
            .pic {
                @include res(height, 447px, (md:fit-content));
                position: relative;
                overflow: hidden;
                .image {
                    top: -100px;
                    width: 100%;
                    @include res(height, 447px, (md:fit-content));
                    margin: 0!important;
                }
            }
            p {
                @include res(margin-top, 65px, 20 / 65);
                @include res(font-size, 20px, 16 / 20);
                @include res(line-height, 2, 1.6 / 2);
                color: #232323;
            }
            a {
                position: relative;
                @include res(position, relative, (md:absolute));
                @include res(bottom, auto, (md:20px));
                @include res(left, auto, (md:0));
                @include res(right, auto, (md:0));
                @include res(text-align, right, (md:center));
                width: fit-content;
                margin: auto;
                display: block;
                float: right;
                font-family: 'ProximaNova-Black';
                @include res(font-size, 20px, 16 / 20);
                color: #232323;
                @include res(margin-top, 65px, 20 / 65);
                &:before,&:after {
                    content: " ";
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    top: auto;
                    bottom: -5px;
                    height: 2px;
                    background-color: #000;
                    background-color: #000000;
                    opacity: 0.3;
                }
                &:after {
                    width: 0;
                    left: auto;
                    right: 0;
                    opacity: 1;
                    transition-duration: 0.3s;
                    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                    background-color: #fff;
                }
                &:hover {
                    &:after {
                        width: 100%;
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
        .right {
            position: relative;
            z-index: 1;
            @include res(margin-top, 70px, 30 / 70);
            @include res(width, 444px,(lg:37%, md:100%));
            .pic {
                @include res(height, 617px, (md:fit-content));
                position: relative;
                overflow: hidden;
                .image {
                    top: -100px;
                    width: 100%;
                    @include res(height, 617px, (md:fit-content));
                    margin: 0 !important;
                }
            }
            h3 {
                margin-left: 5px;
                font-family: 'ProximaNova-Black';
                @include res(font-size, 40px, 26 / 40);
                color: #232323;
                margin-bottom: 30px;
            }
        }
    }
    .container {
        @include res(padding-bottom, 120px, 70 / 120);
        overflow: hidden;
        position: relative;
        @include res(padding-top, 120px, 40 / 120);
        .slash {
            @include res(display, block, (md:none));
            position: absolute;
            width: 1px;
            height: 1500px;
            background: #19908B;
            top: -100px;
            right: 470px;
            transform: rotate(7.5625deg);
        }
    }
}

// 产品
.products {
    width: 100%;
    position: relative;
    .back {
        z-index: 0;
        position: absolute;
        width: 100%;
        @include res(height, 492px,(lg:477px,mmd:462px,md:434px,sm:368px,xs:353px));
        background-color: #01847f;
        top: 0;
    }
    .content {
        position: relative;
        z-index: 1;
        .pro-nav {
            @include res(padding-top, 55px,(md:20px));
            @include res(padding-bottom, 0, (md:20px));
            @include res(margin-left,125px,(mmd:40px,md:20px));
            display: flex;
            align-items: center;
            a {
                position: relative;
                display: block;
                margin-bottom: 5px;
                @include res(font-size,90px, 28 / 90 );
                @include res(margin-right, 93px, 30 / 93);
                color: rgba($color: #ffffff, $alpha: .3);
                font-family: 'ProximaNova-Black';
                transition: all .3s;
                &:last-child {
                    margin-right: 0;
                }
                &:hover,&.active {
                    color: #fff;
                    span {
                        background: #fff;
                    }
                }
                span {
                    display: block;
                    @include res(width, 10px, 8 / 10);
                    @include res(height, 10px, 8 / 10);
                    border-radius: 50%;
                    background: transparent;
                    position: absolute;
                    @include res(bottom, 5px, (md:-7px));
                    left: 0;
                    right: 0;
                    margin: auto;
                    transition: all .3s;
                }
                
            }
        }
        .pro-content {
            display: none;
            .little-nav {
                position: relative;
                @include res(overflow-x, hidden, (xs:auto));
                @include res(overflow-y, hidden);
                @include res(padding-right, 0, (xs:25px));
                width: 100%;
                height: 70px;
                box-sizing: border-box;
                border-bottom: 1px solid rgba($color: #ffffff, $alpha: .3);
                border-top: 1px solid rgba($color: #ffffff, $alpha: .3);
                display: flex;
                align-items: center;
                @include res(padding-left, 135px,(mmd:50px,md:25px));
                a {
                    white-space:nowrap;
                    position: relative;
                    font-family: 'ProximaNova-Bold';
                    @include res(font-size, 20px, 16 / 20);
                    color: #fff;
                    @include res(margin-right, 55px, 20 / 55);
                    &:last-child {
                        margin-right: 0;
                    }
                }
                span {
                    display: block;
                    width: 0;
                    height: 3px;
                    background-color: #ffffff;
                    -webkit-border-radius: 2px;
                    -moz-border-radius: 2px;
                    border-radius: 2px;
                    margin-top: 10px;
                    -webkit-transition: all .3s;
                    -o-transition: all .3s;
                    -moz-transition: all .3s;
                    transition: all .3s;
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                }
            }
            .container {
                display: none;
                margin-top: 65px;
                @include res(max-width, 1670px);
                ul {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    li {
                        margin-bottom: 35px;
                        @include res(width, fit-content, (mmd:48%,xs:100%));
                        .top {
                            @include res(padding-left, 50px, (lg:35px));
                            padding-left: 50px;
                            position: relative;
                            display: flex;
                            @include res(justify-content, start);
                            .pic {
                                @include res(width, fit-content, (sm:180px));
                                @include res(position, static, (sm:relative));
                                img {
                                    display: block;
                                    @include res(position, static, (sm:absolute));
                                    bottom: 0;
                                    width: 100%;
                                    max-width: fit-content;
                                }
                            }
                            h2 {
                                position: relative;
                                margin-top: 100px;
                                font-family: 'ProximaNova-Black';
                                @include res(font-size, 60px, (lg:46px,mmd:40px,md:32px));
                                color: #2b2b2b;
                                @include res(top, 0, (sm:-20px));
                                @include res(margin-left, 35px, (lg:20px));
                                &:before,
                                &:after {
                                    content: " ";
                                    position: absolute;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    top: auto;
                                    @include res(bottom, 15px, (sm:-5px));
                                    height: 3px;
                                    background-color: #fff;
                                }
                            
                                &:after {
                                    width: 0;
                                    left: auto;
                                    right: 0;
                                    opacity: 1;
                                    transition-duration: 0.3s;
                                    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                                    background-color: #2b2b2b;
                                }
                            
                            }
                        }
                        .text {
                            border-left: 1px solid rgba(0,0,0,.1);
                            @include res(max-width, 480px, (lg:400px,mmd:fit-content));
                            @include res(padding-left, 50px, (lg:35px));
                            margin-bottom: 35px;
                            h3 {
                                @include res(padding-top, 30px, (xs:15px));
                                font-family: 'ProximaNova-Black';
                                @include res(font-size, 20px, 16 / 20);
                                color: #2b2b2b;
                            }
                            p {
                                color: #2b2b2b;
                                @include res(font-size, 18px, 14 / 18);
                                @include res(line-height, 1.6);
                            }
                        }
                        &:nth-child(1) {
                            .top {
                                h2 {
                                    @include res(color, #ffffff);
                                    &:before {
                                        background: #01847F;
                                    }
                                    &:after {
                                        background: #fff;
                                    }
                                }
                            }
                        }
                        &:nth-child(2) {
                            .top {
                                h2 {
                                    @include res(color, #ffffff,(xs:#2b2b2b));
                                    &:before {
                                        @include res(background, #01847F, (xs:#fff));
                                    }
                                    &:after {
                                        @include res(background, #fff, (xs:#2b2b2b));
                                    }
                                }
                                
                            }
                        }
                        &:nth-child(3) {
                            .top {
                                h2 {
                                    @include res(color, #ffffff,(mmd:#2b2b2b));
                                    &:before {
                                        @include res(background, #01847F, (mmd:#fff));
                                    }
                                    &:after {
                                        @include res(background, #fff, (mmd:#2b2b2b));
                                    }
                                }
                            }
                        }
                        &:hover {
                            .top {
                                h2 {
                                    &:after {
                                        width: 100%;
                                        right: auto;
                                        left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .more {
                    display: block;
                    width: 100%;
                    @include res(height, 67px,40 / 67);
                    @include res(line-height, 67px, 40 / 67);
                    background-color: #eeeeee;
                    @include res(border-radius, 33px, 20 / 33);
                    text-align: center;
                    font-family: 'ProximaNova-Black';
                    @include res(font-size, 20px, 16 / 20);
                    color: #2b2b2b;
                    transition: all .1s;
                    &:hover {
                        color: #01847F;
                    }
                    >div{
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;
                        @include res(height, 67px, 40 / 67);
                        span{
                            transition: all .3s;
                        }
                    }
                    &:hover{
                        >div{
                            span{
                                transform: translateY(-100%);
                            }
                        }
                    }

                }
            }
        }
    }
}

// 新闻
.news {
    @include res(margin-top, 140px, 40 / 140);
    @include res(margin-bottom, 100px, 50 / 100);
    h2 {
        font-family: 'ProximaNova-Black';
        text-align: center;
        @include res(font-size, 90px, 40 / 90);
        color: #010101;
        @include res(margin-bottom, 40px, 20 / 40);
    }
    .container {
        position: relative;
        .swiper-slide {
            a {
                display: flex;
                flex-wrap: wrap;
                .pic {
                    @include res(width, fit-content,(sm:44%,xs:100%));
                    img {
                        display: block;
                        @include res(width, fit-content, (xs:100%));
                        width: 100%;
                        transition: all .3s;
                    }
                    position: relative;
                    overflow: hidden;
                    
                    .model {
                        position: absolute;
                        top: 0;
                        left: 0;
                        @include res(width, 205px, (sm:35%));
                        border-top-width: 0px;
                        border-right-width: 0px;
                        @include res(border-bottom-width, 338px, (xs:412px));
                        border-left-width: 60px;
                        border-style: none solid solid;
                        border-color: transparent transparent rgba(1,132,127,.6);
                        transform: rotate(180deg);
                        transition: all .3s;
                    }
                    .line {
                        position: absolute;
                        border: solid rgba($color: #000000, $alpha: 0.3);
                        border-width: 0 0 0 1px;
                        transform: skewX(-15deg);
                        left: 47%;
                        width: 1px;
                        height: 100%;
                        top: 0;
                        z-index: 1;
                        transition: all .3s;
                    }
                    &:hover {
                        img {
                            @include res(transform, scale(1.1), (xs:scale(1)));
                        }
                        .model {
                            width: 230px;
                            @include res(width, 230px, (xs:35%));
                        }
                        .line {
                            transform: skewX(-20deg);
                        }
                    }
                }
                .text-box {
                    @include res(width, fit-content, ( xs:100%));
                    flex: 1;
                    background: #F6F6F6;
                    box-sizing: border-box;
                    @include res(padding-top, 85px, 30 / 85);
                    @include res(padding-left, 80px, 20 / 80);
                    @include res(padding-right, 80px, 20 / 80);
                    h3 {
                        font-family: 'ProximaNova-Bold';
                        @include res(font-size, 30px, 20 / 30);
                        color: #01847f;
                    }
                    p {
                        margin-top: 20px;
                        @include res(font-size, 18px, 14 / 18);
                        color: rgba($color: #000000, $alpha: 0.6);
                        @include res(line-height, 1.6);
                    }
                }
            }
        }
        .swiper-button-next {
            transition: all .3s;
            @include res(right, -24px,(mmd:0px));
            @include res(width,60px,(mmd:40px));
            @include res(height, 60px,(mmd:40px));
            top:40%;
            border-radius: 50%;
            background: #666666;
            &:after {
                content: none;
            }
            &:hover {
                background: #01847F;
            }
            i {
                font-size: 30px;
                color: #fff;
            }
            

        }
        .swiper-button-prev {
            transition: all .3s;
            top: 40%;
            @include res(left, -24px,(mmd:0px));
            @include res(width,60px,(mmd:40px) );
            @include res(height, 60px,(mmd:40px));
            border-radius: 50%;
            background: #666666;
           &:after {
                content: none;
            }
            &:hover {
                background: #01847F;
            }
            i {
                font-size: 30px;
                color: #fff;
            }
        }
        .bottom {
            display: flex;
            margin-top: 40px;
            justify-content: space-between;
            .swiper-pagination {
                position: relative;
                text-align: left;
                width: fit-content;
                span {
                    @include res(font-size, 18px, 14 / 18);
                    color: #000;
                }
                .swiper-pagination-current {
                    color: #01847f;
                }
            }
            a {
                position: relative;
                margin-top: -10px;
                font-family: 'ProximaNova-Black';
                @include res(font-size, 20px, 16 / 20);
                color: #2b2b2b;
                &:before,&:after {
                    content: " ";
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    top: auto;
                    bottom: -5px;
                    height: 2px;
                    background-color: #000;
                    background-color: #000000;
                    opacity: 0.3;
                }
                &:after {
                    width: 0;
                    left: auto;
                    right: 0;
                    opacity: 1;
                    transition-duration: 0.3s;
                    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                    background-color: #fff;
                }
                &:hover {
                    &:after {
                        width: 100%;
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
    }
}