.banner {
  overflow: hidden;
  width: 100%;
  height: 300px;
  position: relative;
}

.banner .model {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.banner .pic {
  width: 100%;
  height: 100%;
  transition: all .5s;
}

.banner .pic div {
  position: absolute;
  transition: all .5s;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.banner .title {
  top: 80px;
  top: 80px;
  position: absolute;
  width: fit-content;
  height: calc(100% + 50px);
  left: 512px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 10;
  transform: rotate(15deg);
}

@media (max-width: 1024px) {
  .banner .title {
    top: 50px;
  }
}

@media (max-width: 767px) {
  .banner .title {
    top: 34px;
  }
}

@media (max-width: 1366px) {
  .banner .title {
    left: 330px;
  }
}

@media (max-width: 1024px) {
  .banner .title {
    left: 220px;
  }
}

@media (max-width: 767px) {
  .banner .title {
    left: 19%;
  }
}

.banner .title .line {
  display: block;
  position: absolute;
  left: -2px;
  top: 40%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .banner .title .line {
    display: none;
  }
}

.banner .title .line div {
  position: relative;
  width: 4px;
  height: 85px;
  background: #01847F;
  top: 105px;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .banner .title .line div {
    height: 60px;
  }
}

@media (max-width: 1366px) {
  .banner .title .line div {
    height: 51px;
  }
}

@media (max-width: 991px) {
  .banner .title .line div {
    height: 42px;
  }
}

@media (max-width: 1600px) {
  .banner .title .line div {
    top: 95px;
  }
}

@media (max-width: 1366px) {
  .banner .title .line div {
    top: 83px;
  }
}

@media (max-width: 1024px) {
  .banner .title .line div {
    top: 70px;
  }
}

@media (max-width: 991px) {
  .banner .title .line div {
    top: 64px;
  }
}

@media (max-width: 767px) {
  .banner .title .line div {
    top: 23px;
  }
}

.banner .title .banner-nav {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .banner .title .banner-nav {
    top: 32%;
  }
}

.banner .title .banner-nav div {
  width: 100%;
  height: fit-content;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .banner .title .banner-nav div {
    margin-bottom: 20px;
  }
}

.banner .title .banner-nav div:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .banner .title .banner-nav div:last-child {
    margin-bottom: 0;
  }
}

.banner .title .banner-nav div h2 {
  transform: rotate(-15deg);
  padding-left: 0;
  color: rgba(255, 255, 255, 0.3);
  font-family: 'ProximaNova-Black';
  font-size: 80px;
  font-weight: bold;
  transition: all .3s;
  text-transform: uppercase;
  font-style: oblique;
}

@media (max-width: 767px) {
  .banner .title .banner-nav div h2 {
    padding-left: 0;
  }
}

@media (max-width: 1600px) {
  .banner .title .banner-nav div h2 {
    font-size: 70.4px;
  }
}

@media (max-width: 1366px) {
  .banner .title .banner-nav div h2 {
    font-size: 60.8px;
  }
}

@media (max-width: 1024px) {
  .banner .title .banner-nav div h2 {
    font-size: 51.2px;
  }
}

@media (max-width: 991px) {
  .banner .title .banner-nav div h2 {
    font-size: 41.6px;
  }
}

@media (max-width: 767px) {
  .banner .title .banner-nav div h2 {
    font-size: 32px;
  }
}

.banner .title .banner-nav div.active h2 {
  color: #ffffff;
}

.banner .scroll {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 11;
}

@media (max-width: 1024px) {
  .banner .scroll {
    display: none;
  }
}

.banner .scroll span {
  font-size: 20px;
  font-family: 'ProximaNova-Black';
  display: block;
  color: #fff;
  margin-bottom: 58px;
  margin-right: 155px;
}

@media (max-width: 1600px) {
  .banner .scroll span {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .banner .scroll span {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .banner .scroll span {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .banner .scroll span {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .banner .scroll span {
    font-size: 16px;
  }
}

.banner .scroll .line {
  width: 1px;
  height: 1000px;
  background: rgba(255, 255, 255, 0.3);
  transform: rotate(15deg);
  position: absolute;
  bottom: -30px;
  right: 0;
}

@media (max-width: 1600px) {
  .banner .scroll .line {
    height: 860px;
  }
}

@media (max-width: 1366px) {
  .banner .scroll .line {
    height: 720px;
  }
}

@media (max-width: 1024px) {
  .banner .scroll .line {
    height: 580px;
  }
}

@media (max-width: 991px) {
  .banner .scroll .line {
    height: 440px;
  }
}

@media (max-width: 767px) {
  .banner .scroll .line {
    height: 300px;
  }
}

.about {
  position: relative;
}

.about h2 {
  position: relative;
  z-index: 1;
  text-align: right;
  font-family: 'ProximaNova-Black';
  font-size: 90px;
  color: #01847f;
}

@media (max-width: 1600px) {
  .about h2 {
    font-size: 78px;
  }
}

@media (max-width: 1366px) {
  .about h2 {
    font-size: 66px;
  }
}

@media (max-width: 1024px) {
  .about h2 {
    font-size: 54px;
  }
}

@media (max-width: 991px) {
  .about h2 {
    font-size: 42px;
  }
}

@media (max-width: 767px) {
  .about h2 {
    font-size: 30px;
  }
}

.about .content {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1600px) {
  .about .content {
    margin-top: 54px;
  }
}

@media (max-width: 1366px) {
  .about .content {
    margin-top: 48px;
  }
}

@media (max-width: 1024px) {
  .about .content {
    margin-top: 42px;
  }
}

@media (max-width: 991px) {
  .about .content {
    margin-top: 36px;
  }
}

@media (max-width: 767px) {
  .about .content {
    margin-top: 30px;
  }
}

@media (max-width: 1024px) {
  .about .content {
    display: block;
  }
}

.about .content img {
  display: block;
}

.about .content .left {
  z-index: 1;
  position: relative;
  width: 694px;
}

@media (max-width: 1024px) {
  .about .content .left {
    position: static;
  }
}

@media (max-width: 1600px) {
  .about .content .left {
    width: 57.8%;
  }
}

@media (max-width: 1024px) {
  .about .content .left {
    width: 100%;
  }
}

.about .content .left .pic {
  height: 447px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .about .content .left .pic {
    height: fit-content;
  }
}

.about .content .left .pic .image {
  top: -100px;
  width: 100%;
  height: 447px;
  margin: 0 !important;
}

@media (max-width: 1024px) {
  .about .content .left .pic .image {
    height: fit-content;
  }
}

.about .content .left p {
  margin-top: 65px;
  font-size: 20px;
  line-height: 2;
  color: #232323;
}

@media (max-width: 1600px) {
  .about .content .left p {
    margin-top: 56px;
  }
}

@media (max-width: 1366px) {
  .about .content .left p {
    margin-top: 47px;
  }
}

@media (max-width: 1024px) {
  .about .content .left p {
    margin-top: 38px;
  }
}

@media (max-width: 991px) {
  .about .content .left p {
    margin-top: 29px;
  }
}

@media (max-width: 767px) {
  .about .content .left p {
    margin-top: 20px;
  }
}

@media (max-width: 1600px) {
  .about .content .left p {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .about .content .left p {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .about .content .left p {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .about .content .left p {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .about .content .left p {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .about .content .left p {
    line-height: 1.92;
  }
}

@media (max-width: 1366px) {
  .about .content .left p {
    line-height: 1.84;
  }
}

@media (max-width: 1024px) {
  .about .content .left p {
    line-height: 1.76;
  }
}

@media (max-width: 991px) {
  .about .content .left p {
    line-height: 1.68;
  }
}

@media (max-width: 767px) {
  .about .content .left p {
    line-height: 1.6;
  }
}

.about .content .left a {
  position: relative;
  position: relative;
  bottom: auto;
  left: auto;
  right: auto;
  text-align: right;
  width: fit-content;
  margin: auto;
  display: block;
  float: right;
  font-family: 'ProximaNova-Black';
  font-size: 20px;
  color: #232323;
  margin-top: 65px;
}

@media (max-width: 1024px) {
  .about .content .left a {
    position: absolute;
  }
}

@media (max-width: 1024px) {
  .about .content .left a {
    bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .about .content .left a {
    left: 0;
  }
}

@media (max-width: 1024px) {
  .about .content .left a {
    right: 0;
  }
}

@media (max-width: 1024px) {
  .about .content .left a {
    text-align: center;
  }
}

@media (max-width: 1600px) {
  .about .content .left a {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .about .content .left a {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .about .content .left a {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .about .content .left a {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .about .content .left a {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .about .content .left a {
    margin-top: 56px;
  }
}

@media (max-width: 1366px) {
  .about .content .left a {
    margin-top: 47px;
  }
}

@media (max-width: 1024px) {
  .about .content .left a {
    margin-top: 38px;
  }
}

@media (max-width: 991px) {
  .about .content .left a {
    margin-top: 29px;
  }
}

@media (max-width: 767px) {
  .about .content .left a {
    margin-top: 20px;
  }
}

.about .content .left a:before, .about .content .left a:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: auto;
  bottom: -5px;
  height: 2px;
  background-color: #000;
  background-color: #000000;
  opacity: 0.3;
}

.about .content .left a:after {
  width: 0;
  left: auto;
  right: 0;
  opacity: 1;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: #fff;
}

.about .content .left a:hover:after {
  width: 100%;
  right: auto;
  left: 0;
}

.about .content .right {
  position: relative;
  z-index: 1;
  margin-top: 70px;
  width: 444px;
}

@media (max-width: 1600px) {
  .about .content .right {
    margin-top: 62px;
  }
}

@media (max-width: 1366px) {
  .about .content .right {
    margin-top: 54px;
  }
}

@media (max-width: 1024px) {
  .about .content .right {
    margin-top: 46px;
  }
}

@media (max-width: 991px) {
  .about .content .right {
    margin-top: 38px;
  }
}

@media (max-width: 767px) {
  .about .content .right {
    margin-top: 30px;
  }
}

@media (max-width: 1600px) {
  .about .content .right {
    width: 37%;
  }
}

@media (max-width: 1024px) {
  .about .content .right {
    width: 100%;
  }
}

.about .content .right .pic {
  height: 617px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .about .content .right .pic {
    height: fit-content;
  }
}

.about .content .right .pic .image {
  top: -100px;
  width: 100%;
  height: 617px;
  margin: 0 !important;
}

@media (max-width: 1024px) {
  .about .content .right .pic .image {
    height: fit-content;
  }
}

.about .content .right h3 {
  margin-left: 5px;
  font-family: 'ProximaNova-Black';
  font-size: 40px;
  color: #232323;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .about .content .right h3 {
    font-size: 37.2px;
  }
}

@media (max-width: 1366px) {
  .about .content .right h3 {
    font-size: 34.4px;
  }
}

@media (max-width: 1024px) {
  .about .content .right h3 {
    font-size: 31.6px;
  }
}

@media (max-width: 991px) {
  .about .content .right h3 {
    font-size: 28.8px;
  }
}

@media (max-width: 767px) {
  .about .content .right h3 {
    font-size: 26px;
  }
}

.about .container {
  padding-bottom: 120px;
  overflow: hidden;
  position: relative;
  padding-top: 120px;
}

@media (max-width: 1600px) {
  .about .container {
    padding-bottom: 110px;
  }
}

@media (max-width: 1366px) {
  .about .container {
    padding-bottom: 100px;
  }
}

@media (max-width: 1024px) {
  .about .container {
    padding-bottom: 90px;
  }
}

@media (max-width: 991px) {
  .about .container {
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .about .container {
    padding-bottom: 70px;
  }
}

@media (max-width: 1600px) {
  .about .container {
    padding-top: 104px;
  }
}

@media (max-width: 1366px) {
  .about .container {
    padding-top: 88px;
  }
}

@media (max-width: 1024px) {
  .about .container {
    padding-top: 72px;
  }
}

@media (max-width: 991px) {
  .about .container {
    padding-top: 56px;
  }
}

@media (max-width: 767px) {
  .about .container {
    padding-top: 40px;
  }
}

.about .container .slash {
  display: block;
  position: absolute;
  width: 1px;
  height: 1500px;
  background: #19908B;
  top: -100px;
  right: 470px;
  transform: rotate(7.5625deg);
}

@media (max-width: 1024px) {
  .about .container .slash {
    display: none;
  }
}

.products {
  width: 100%;
  position: relative;
}

.products .back {
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 492px;
  background-color: #01847f;
  top: 0;
}

@media (max-width: 1600px) {
  .products .back {
    height: 477px;
  }
}

@media (max-width: 1366px) {
  .products .back {
    height: 462px;
  }
}

@media (max-width: 1024px) {
  .products .back {
    height: 434px;
  }
}

@media (max-width: 991px) {
  .products .back {
    height: 368px;
  }
}

@media (max-width: 767px) {
  .products .back {
    height: 353px;
  }
}

.products .content {
  position: relative;
  z-index: 1;
}

.products .content .pro-nav {
  padding-top: 55px;
  padding-bottom: 0;
  margin-left: 125px;
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .products .content .pro-nav {
    padding-top: 20px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-nav {
    padding-bottom: 20px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-nav {
    margin-left: 40px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-nav {
    margin-left: 20px;
  }
}

.products .content .pro-nav a {
  position: relative;
  display: block;
  margin-bottom: 5px;
  font-size: 90px;
  margin-right: 93px;
  color: rgba(255, 255, 255, 0.3);
  font-family: 'ProximaNova-Black';
  transition: all .3s;
}

@media (max-width: 1600px) {
  .products .content .pro-nav a {
    font-size: 77.6px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-nav a {
    font-size: 65.2px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-nav a {
    font-size: 52.8px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-nav a {
    font-size: 40.4px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-nav a {
    font-size: 28px;
  }
}

@media (max-width: 1600px) {
  .products .content .pro-nav a {
    margin-right: 80.4px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-nav a {
    margin-right: 67.8px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-nav a {
    margin-right: 55.2px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-nav a {
    margin-right: 42.6px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-nav a {
    margin-right: 30px;
  }
}

.products .content .pro-nav a:last-child {
  margin-right: 0;
}

.products .content .pro-nav a:hover, .products .content .pro-nav a.active {
  color: #fff;
}

.products .content .pro-nav a:hover span, .products .content .pro-nav a.active span {
  background: #fff;
}

.products .content .pro-nav a span {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: auto;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .products .content .pro-nav a span {
    width: 9.6px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-nav a span {
    width: 9.2px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-nav a span {
    width: 8.8px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-nav a span {
    width: 8.4px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-nav a span {
    width: 8px;
  }
}

@media (max-width: 1600px) {
  .products .content .pro-nav a span {
    height: 9.6px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-nav a span {
    height: 9.2px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-nav a span {
    height: 8.8px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-nav a span {
    height: 8.4px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-nav a span {
    height: 8px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-nav a span {
    bottom: -7px;
  }
}

.products .content .pro-content {
  display: none;
}

.products .content .pro-content .little-nav {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: 0;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  padding-left: 135px;
}

@media (max-width: 767px) {
  .products .content .pro-content .little-nav {
    overflow-x: auto;
  }
}

@media (max-width: 767px) {
  .products .content .pro-content .little-nav {
    padding-right: 25px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .little-nav {
    padding-left: 50px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-content .little-nav {
    padding-left: 25px;
  }
}

.products .content .pro-content .little-nav a {
  white-space: nowrap;
  position: relative;
  font-family: 'ProximaNova-Bold';
  font-size: 20px;
  color: #fff;
  margin-right: 55px;
}

@media (max-width: 1600px) {
  .products .content .pro-content .little-nav a {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .little-nav a {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-content .little-nav a {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-content .little-nav a {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-content .little-nav a {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .products .content .pro-content .little-nav a {
    margin-right: 48px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .little-nav a {
    margin-right: 41px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-content .little-nav a {
    margin-right: 34px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-content .little-nav a {
    margin-right: 27px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-content .little-nav a {
    margin-right: 20px;
  }
}

.products .content .pro-content .little-nav a:last-child {
  margin-right: 0;
}

.products .content .pro-content .little-nav span {
  display: block;
  width: 0;
  height: 3px;
  background-color: #ffffff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-top: 10px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  position: absolute;
  bottom: 0px;
  left: 0;
}

.products .content .pro-content .container {
  display: none;
  margin-top: 65px;
  max-width: 1670px;
}

.products .content .pro-content .container ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.products .content .pro-content .container ul li {
  margin-bottom: 35px;
  width: fit-content;
}

@media (max-width: 1366px) {
  .products .content .pro-content .container ul li {
    width: 48%;
  }
}

@media (max-width: 767px) {
  .products .content .pro-content .container ul li {
    width: 100%;
  }
}

.products .content .pro-content .container ul li .top {
  padding-left: 50px;
  padding-left: 50px;
  position: relative;
  display: flex;
  justify-content: start;
}

@media (max-width: 1600px) {
  .products .content .pro-content .container ul li .top {
    padding-left: 35px;
  }
}

.products .content .pro-content .container ul li .top .pic {
  width: fit-content;
  position: static;
}

@media (max-width: 991px) {
  .products .content .pro-content .container ul li .top .pic {
    width: 180px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-content .container ul li .top .pic {
    position: relative;
  }
}

.products .content .pro-content .container ul li .top .pic img {
  display: block;
  position: static;
  bottom: 0;
  width: 100%;
  max-width: fit-content;
}

@media (max-width: 991px) {
  .products .content .pro-content .container ul li .top .pic img {
    position: absolute;
  }
}

.products .content .pro-content .container ul li .top h2 {
  position: relative;
  margin-top: 100px;
  font-family: 'ProximaNova-Black';
  font-size: 60px;
  color: #2b2b2b;
  top: 0;
  margin-left: 35px;
}

@media (max-width: 1600px) {
  .products .content .pro-content .container ul li .top h2 {
    font-size: 46px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .container ul li .top h2 {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-content .container ul li .top h2 {
    font-size: 32px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-content .container ul li .top h2 {
    top: -20px;
  }
}

@media (max-width: 1600px) {
  .products .content .pro-content .container ul li .top h2 {
    margin-left: 20px;
  }
}

.products .content .pro-content .container ul li .top h2:before, .products .content .pro-content .container ul li .top h2:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: auto;
  bottom: 15px;
  height: 3px;
  background-color: #fff;
}

@media (max-width: 991px) {
  .products .content .pro-content .container ul li .top h2:before, .products .content .pro-content .container ul li .top h2:after {
    bottom: -5px;
  }
}

.products .content .pro-content .container ul li .top h2:after {
  width: 0;
  left: auto;
  right: 0;
  opacity: 1;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: #2b2b2b;
}

.products .content .pro-content .container ul li .text {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 480px;
  padding-left: 50px;
  margin-bottom: 35px;
}

@media (max-width: 1600px) {
  .products .content .pro-content .container ul li .text {
    max-width: 400px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .container ul li .text {
    max-width: fit-content;
  }
}

@media (max-width: 1600px) {
  .products .content .pro-content .container ul li .text {
    padding-left: 35px;
  }
}

.products .content .pro-content .container ul li .text h3 {
  padding-top: 30px;
  font-family: 'ProximaNova-Black';
  font-size: 20px;
  color: #2b2b2b;
}

@media (max-width: 767px) {
  .products .content .pro-content .container ul li .text h3 {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .products .content .pro-content .container ul li .text h3 {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .container ul li .text h3 {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-content .container ul li .text h3 {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-content .container ul li .text h3 {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-content .container ul li .text h3 {
    font-size: 16px;
  }
}

.products .content .pro-content .container ul li .text p {
  color: #2b2b2b;
  font-size: 18px;
  line-height: 1.6;
}

@media (max-width: 1600px) {
  .products .content .pro-content .container ul li .text p {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .container ul li .text p {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-content .container ul li .text p {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-content .container ul li .text p {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-content .container ul li .text p {
    font-size: 14px;
  }
}

.products .content .pro-content .container ul li:nth-child(1) .top h2 {
  color: #ffffff;
}

.products .content .pro-content .container ul li:nth-child(1) .top h2:before {
  background: #01847F;
}

.products .content .pro-content .container ul li:nth-child(1) .top h2:after {
  background: #fff;
}

.products .content .pro-content .container ul li:nth-child(2) .top h2 {
  color: #ffffff;
}

@media (max-width: 767px) {
  .products .content .pro-content .container ul li:nth-child(2) .top h2 {
    color: #2b2b2b;
  }
}

.products .content .pro-content .container ul li:nth-child(2) .top h2:before {
  background: #01847F;
}

@media (max-width: 767px) {
  .products .content .pro-content .container ul li:nth-child(2) .top h2:before {
    background: #fff;
  }
}

.products .content .pro-content .container ul li:nth-child(2) .top h2:after {
  background: #fff;
}

@media (max-width: 767px) {
  .products .content .pro-content .container ul li:nth-child(2) .top h2:after {
    background: #2b2b2b;
  }
}

.products .content .pro-content .container ul li:nth-child(3) .top h2 {
  color: #ffffff;
}

@media (max-width: 1366px) {
  .products .content .pro-content .container ul li:nth-child(3) .top h2 {
    color: #2b2b2b;
  }
}

.products .content .pro-content .container ul li:nth-child(3) .top h2:before {
  background: #01847F;
}

@media (max-width: 1366px) {
  .products .content .pro-content .container ul li:nth-child(3) .top h2:before {
    background: #fff;
  }
}

.products .content .pro-content .container ul li:nth-child(3) .top h2:after {
  background: #fff;
}

@media (max-width: 1366px) {
  .products .content .pro-content .container ul li:nth-child(3) .top h2:after {
    background: #2b2b2b;
  }
}

.products .content .pro-content .container ul li:hover .top h2:after {
  width: 100%;
  right: auto;
  left: 0;
}

.products .content .pro-content .container .more {
  display: block;
  width: 100%;
  height: 67px;
  line-height: 67px;
  background-color: #eeeeee;
  border-radius: 33px;
  text-align: center;
  font-family: 'ProximaNova-Black';
  font-size: 20px;
  color: #2b2b2b;
  transition: all .1s;
}

@media (max-width: 1600px) {
  .products .content .pro-content .container .more {
    height: 61.6px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .container .more {
    height: 56.2px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-content .container .more {
    height: 50.8px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-content .container .more {
    height: 45.4px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-content .container .more {
    height: 40px;
  }
}

@media (max-width: 1600px) {
  .products .content .pro-content .container .more {
    line-height: 61.6px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .container .more {
    line-height: 56.2px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-content .container .more {
    line-height: 50.8px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-content .container .more {
    line-height: 45.4px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-content .container .more {
    line-height: 40px;
  }
}

@media (max-width: 1600px) {
  .products .content .pro-content .container .more {
    border-radius: 30.4px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .container .more {
    border-radius: 27.8px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-content .container .more {
    border-radius: 25.2px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-content .container .more {
    border-radius: 22.6px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-content .container .more {
    border-radius: 20px;
  }
}

@media (max-width: 1600px) {
  .products .content .pro-content .container .more {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .container .more {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-content .container .more {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-content .container .more {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-content .container .more {
    font-size: 16px;
  }
}

.products .content .pro-content .container .more:hover {
  color: #01847F;
}

.products .content .pro-content .container .more > div {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 67px;
}

@media (max-width: 1600px) {
  .products .content .pro-content .container .more > div {
    height: 61.6px;
  }
}

@media (max-width: 1366px) {
  .products .content .pro-content .container .more > div {
    height: 56.2px;
  }
}

@media (max-width: 1024px) {
  .products .content .pro-content .container .more > div {
    height: 50.8px;
  }
}

@media (max-width: 991px) {
  .products .content .pro-content .container .more > div {
    height: 45.4px;
  }
}

@media (max-width: 767px) {
  .products .content .pro-content .container .more > div {
    height: 40px;
  }
}

.products .content .pro-content .container .more > div span {
  transition: all .3s;
}

.products .content .pro-content .container .more:hover > div span {
  transform: translateY(-100%);
}

.news {
  margin-top: 140px;
  margin-bottom: 100px;
}

@media (max-width: 1600px) {
  .news {
    margin-top: 120px;
  }
}

@media (max-width: 1366px) {
  .news {
    margin-top: 100px;
  }
}

@media (max-width: 1024px) {
  .news {
    margin-top: 80px;
  }
}

@media (max-width: 991px) {
  .news {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .news {
    margin-top: 40px;
  }
}

@media (max-width: 1600px) {
  .news {
    margin-bottom: 90px;
  }
}

@media (max-width: 1366px) {
  .news {
    margin-bottom: 80px;
  }
}

@media (max-width: 1024px) {
  .news {
    margin-bottom: 70px;
  }
}

@media (max-width: 991px) {
  .news {
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .news {
    margin-bottom: 50px;
  }
}

.news h2 {
  font-family: 'ProximaNova-Black';
  text-align: center;
  font-size: 90px;
  color: #010101;
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .news h2 {
    font-size: 80px;
  }
}

@media (max-width: 1366px) {
  .news h2 {
    font-size: 70px;
  }
}

@media (max-width: 1024px) {
  .news h2 {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .news h2 {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .news h2 {
    font-size: 40px;
  }
}

@media (max-width: 1600px) {
  .news h2 {
    margin-bottom: 36px;
  }
}

@media (max-width: 1366px) {
  .news h2 {
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .news h2 {
    margin-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .news h2 {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .news h2 {
    margin-bottom: 20px;
  }
}

.news .container {
  position: relative;
}

.news .container .swiper-slide a {
  display: flex;
  flex-wrap: wrap;
}

.news .container .swiper-slide a .pic {
  width: fit-content;
  position: relative;
  overflow: hidden;
}

@media (max-width: 991px) {
  .news .container .swiper-slide a .pic {
    width: 44%;
  }
}

@media (max-width: 767px) {
  .news .container .swiper-slide a .pic {
    width: 100%;
  }
}

.news .container .swiper-slide a .pic img {
  display: block;
  width: fit-content;
  width: 100%;
  transition: all .3s;
}

@media (max-width: 767px) {
  .news .container .swiper-slide a .pic img {
    width: 100%;
  }
}

.news .container .swiper-slide a .pic .model {
  position: absolute;
  top: 0;
  left: 0;
  width: 205px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 338px;
  border-left-width: 60px;
  border-style: none solid solid;
  border-color: transparent transparent rgba(1, 132, 127, 0.6);
  transform: rotate(180deg);
  transition: all .3s;
}

@media (max-width: 991px) {
  .news .container .swiper-slide a .pic .model {
    width: 35%;
  }
}

@media (max-width: 767px) {
  .news .container .swiper-slide a .pic .model {
    border-bottom-width: 412px;
  }
}

.news .container .swiper-slide a .pic .line {
  position: absolute;
  border: solid rgba(0, 0, 0, 0.3);
  border-width: 0 0 0 1px;
  transform: skewX(-15deg);
  left: 47%;
  width: 1px;
  height: 100%;
  top: 0;
  z-index: 1;
  transition: all .3s;
}

.news .container .swiper-slide a .pic:hover img {
  transform: scale(1.1);
}

@media (max-width: 767px) {
  .news .container .swiper-slide a .pic:hover img {
    transform: scale(1);
  }
}

.news .container .swiper-slide a .pic:hover .model {
  width: 230px;
  width: 230px;
}

@media (max-width: 767px) {
  .news .container .swiper-slide a .pic:hover .model {
    width: 35%;
  }
}

.news .container .swiper-slide a .pic:hover .line {
  transform: skewX(-20deg);
}

.news .container .swiper-slide a .text-box {
  width: fit-content;
  flex: 1;
  background: #F6F6F6;
  box-sizing: border-box;
  padding-top: 85px;
  padding-left: 80px;
  padding-right: 80px;
}

@media (max-width: 767px) {
  .news .container .swiper-slide a .text-box {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .news .container .swiper-slide a .text-box {
    padding-top: 74px;
  }
}

@media (max-width: 1366px) {
  .news .container .swiper-slide a .text-box {
    padding-top: 63px;
  }
}

@media (max-width: 1024px) {
  .news .container .swiper-slide a .text-box {
    padding-top: 52px;
  }
}

@media (max-width: 991px) {
  .news .container .swiper-slide a .text-box {
    padding-top: 41px;
  }
}

@media (max-width: 767px) {
  .news .container .swiper-slide a .text-box {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .news .container .swiper-slide a .text-box {
    padding-left: 68px;
  }
}

@media (max-width: 1366px) {
  .news .container .swiper-slide a .text-box {
    padding-left: 56px;
  }
}

@media (max-width: 1024px) {
  .news .container .swiper-slide a .text-box {
    padding-left: 44px;
  }
}

@media (max-width: 991px) {
  .news .container .swiper-slide a .text-box {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .news .container .swiper-slide a .text-box {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .news .container .swiper-slide a .text-box {
    padding-right: 68px;
  }
}

@media (max-width: 1366px) {
  .news .container .swiper-slide a .text-box {
    padding-right: 56px;
  }
}

@media (max-width: 1024px) {
  .news .container .swiper-slide a .text-box {
    padding-right: 44px;
  }
}

@media (max-width: 991px) {
  .news .container .swiper-slide a .text-box {
    padding-right: 32px;
  }
}

@media (max-width: 767px) {
  .news .container .swiper-slide a .text-box {
    padding-right: 20px;
  }
}

.news .container .swiper-slide a .text-box h3 {
  font-family: 'ProximaNova-Bold';
  font-size: 30px;
  color: #01847f;
}

@media (max-width: 1600px) {
  .news .container .swiper-slide a .text-box h3 {
    font-size: 28px;
  }
}

@media (max-width: 1366px) {
  .news .container .swiper-slide a .text-box h3 {
    font-size: 26px;
  }
}

@media (max-width: 1024px) {
  .news .container .swiper-slide a .text-box h3 {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .news .container .swiper-slide a .text-box h3 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .news .container .swiper-slide a .text-box h3 {
    font-size: 20px;
  }
}

.news .container .swiper-slide a .text-box p {
  margin-top: 20px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.6;
}

@media (max-width: 1600px) {
  .news .container .swiper-slide a .text-box p {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .news .container .swiper-slide a .text-box p {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .news .container .swiper-slide a .text-box p {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .news .container .swiper-slide a .text-box p {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .news .container .swiper-slide a .text-box p {
    font-size: 14px;
  }
}

.news .container .swiper-button-next {
  transition: all .3s;
  right: -24px;
  width: 60px;
  height: 60px;
  top: 40%;
  border-radius: 50%;
  background: #666666;
}

@media (max-width: 1366px) {
  .news .container .swiper-button-next {
    right: 0px;
  }
}

@media (max-width: 1366px) {
  .news .container .swiper-button-next {
    width: 40px;
  }
}

@media (max-width: 1366px) {
  .news .container .swiper-button-next {
    height: 40px;
  }
}

.news .container .swiper-button-next:after {
  content: none;
}

.news .container .swiper-button-next:hover {
  background: #01847F;
}

.news .container .swiper-button-next i {
  font-size: 30px;
  color: #fff;
}

.news .container .swiper-button-prev {
  transition: all .3s;
  top: 40%;
  left: -24px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #666666;
}

@media (max-width: 1366px) {
  .news .container .swiper-button-prev {
    left: 0px;
  }
}

@media (max-width: 1366px) {
  .news .container .swiper-button-prev {
    width: 40px;
  }
}

@media (max-width: 1366px) {
  .news .container .swiper-button-prev {
    height: 40px;
  }
}

.news .container .swiper-button-prev:after {
  content: none;
}

.news .container .swiper-button-prev:hover {
  background: #01847F;
}

.news .container .swiper-button-prev i {
  font-size: 30px;
  color: #fff;
}

.news .container .bottom {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}

.news .container .bottom .swiper-pagination {
  position: relative;
  text-align: left;
  width: fit-content;
}

.news .container .bottom .swiper-pagination span {
  font-size: 18px;
  color: #000;
}

@media (max-width: 1600px) {
  .news .container .bottom .swiper-pagination span {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .news .container .bottom .swiper-pagination span {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .news .container .bottom .swiper-pagination span {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .news .container .bottom .swiper-pagination span {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .news .container .bottom .swiper-pagination span {
    font-size: 14px;
  }
}

.news .container .bottom .swiper-pagination .swiper-pagination-current {
  color: #01847f;
}

.news .container .bottom a {
  position: relative;
  margin-top: -10px;
  font-family: 'ProximaNova-Black';
  font-size: 20px;
  color: #2b2b2b;
}

@media (max-width: 1600px) {
  .news .container .bottom a {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .news .container .bottom a {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .news .container .bottom a {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .news .container .bottom a {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .news .container .bottom a {
    font-size: 16px;
  }
}

.news .container .bottom a:before, .news .container .bottom a:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: auto;
  bottom: -5px;
  height: 2px;
  background-color: #000;
  background-color: #000000;
  opacity: 0.3;
}

.news .container .bottom a:after {
  width: 0;
  left: auto;
  right: 0;
  opacity: 1;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: #fff;
}

.news .container .bottom a:hover:after {
  width: 100%;
  right: auto;
  left: 0;
}
